.noresults {
  width: 90%;
  max-width: 1290px;
  margin: auto;

  padding: 40px;
  border-radius: 9px;
  box-shadow: 0 5px 5px 0 rgba(0, 0, 0, 0.05);
  background-color: var(--white);
  text-align: left;
}

/* Responsive < 600px */
@media screen and (max-width: 600px) {
  .noresults {
    padding: 12px;
  }
}
