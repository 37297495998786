.card__profil {
  /* width: 370px;
  height: 320px; */
  padding: 40px;
  border-radius: 9px;
  box-shadow: 0 5px 5px 0 rgba(0, 0, 0, 0.05);
  background-color: var(--white);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* align-content: center; */
}

.card__profil h2 {
  font-size: 25px;
}

.card__profil a {
  font-size: 11px;
}

.profil__number {
  color: #a3a3a3;
}
