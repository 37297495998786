.lang .active {
  color: black;
  font-weight: bold;
}

.lang span.btn {
  color: black;
  cursor: pointer;
  padding: 5px 10px;
}

.lang span.btn:hover {
  color: var(--black-3);
  background-color: rgba(0, 0, 0, 0.04);
  border-radius: 5px;
}
