/* Filters
--------- */

/* filter-type or date
--------- */

select {
  font-size: 14px;
  width: 100%;
  height: 42px;
  cursor: pointer;
  border-radius: 9px;
  background-color: var(--white);
  border: none;
  color: #4d4f5c;
  padding: 8px 8px 8px 8px;

  max-width: 100%; /* useful when width is set to anything other than 100% */
  box-sizing: border-box;
  margin: 0;
  border: 1px solid white;
  box-shadow: 0 1px 0 1px rgba(0, 0, 0, 0.04);
  border-radius: 0.5em;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  background-color: #fff;

  appearance: none;
  background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIGlkPSJMYXllcl8xIiB4PSIwIiB5PSIwIiB2ZXJzaW9uPSIxLjEiIHZpZXdCb3g9IjAgMCAyOSAyOSIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+PHBhdGggZD0iTTE0LjUgMjcuMDY1YTEyLjQ2NSAxMi40NjUgMCAwIDEtOC44MzktMy42NTVjLTQuODc0LTQuODc0LTQuODc0LTEyLjgwNCAwLTE3LjY3OCAyLjM2MS0yLjM2MSA1LjUtMy42NjIgOC44MzktMy42NjJzNi40NzggMS4zIDguODM5IDMuNjYyYzQuODc0IDQuODc0IDQuODc0IDEyLjgwNCAwIDE3LjY3OGExMi40NjUgMTIuNDY1IDAgMCAxLTguODM5IDMuNjU1em0wLTIyLjk5NWExMC40MyAxMC40MyAwIDAgMC03LjQyNSAzLjA3NmMtNC4wOTMgNC4wOTQtNC4wOTMgMTAuNzU1IDAgMTQuODUgNC4wOTQgNC4wOTMgMTAuNzU1IDQuMDkzIDE0Ljg1IDAgNC4wOTMtNC4wOTQgNC4wOTMtMTAuNzU1IDAtMTQuODVBMTAuNDM0IDEwLjQzNCAwIDAgMCAxNC41IDQuMDd6bTguMTMyIDE4LjYzM2guMDEtLjAxeiIvPjxwYXRoIGQ9Ik0xNC41IDE3Ljg2OWEuOTk3Ljk5NyAwIDAgMS0uNzA3LS4yOTNMOS4xOTcgMTIuOThhLjk5OS45OTkgMCAxIDEgMS40MTQtMS40MTRsMy44ODkgMy44ODkgMy44ODktMy44ODlhLjk5OS45OTkgMCAxIDEgMS40MTQgMS40MTRsLTQuNTk2IDQuNTk2YS45OTcuOTk3IDAgMCAxLS43MDcuMjkzeiIvPjwvc3ZnPg==");
  background-repeat: no-repeat, repeat;
  background-position: right 0.7em top 50%, 0 0;
  background-size: 1.2em auto, 100%;
}

/* For IE <= 11 */
select::-ms-expand {
  display: none;
}

select:hover,
select:focus {
  border: 1px solid var(--ocean-blue);
  color: var(--ocean-blue);
}

/* filter-read
--------- */

.filter-read {
  font-size: 14px;
  width: 100%;
  height: 42px;
  cursor: pointer;
  border-radius: 9px;
  background-color: var(--white);
  border: none;
  color: #4d4f5c;
  display: flex;
  align-items: center;
  box-shadow: 0 1px 0 1px rgba(0, 0, 0, 0.04);
}

.filter__icon {
  width: 100%;
  padding: 8px;
}

.filter__icon:hover,
.filter__icon:focus {
  cursor: pointer;
  color: var(--ocean-blue);
}
