.App-login {
  min-height: 75vh;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;

  /* Bg */
  background-image: url("../images/officium-fullheader.png");
  height: 585px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.App-link {
  color: var(--ocean-blue);
}
