.breadcrumb {
  font-size: 12px;
  font-weight: bold;
  margin: 0 auto 20px auto;
  line-height: 26px;
  text-transform: uppercase;
  text-align: left;
  width: 90%;
  max-width: 1290px;
}

.breadcrumb ul {
  margin: 0 10px;
  padding: 0;
}

.breadcrumb li {
  display: inline-block;
  margin-right: 10px;
}

/* todo: to be tested in several browsers */
.breadcrumb li:not(:first-child)::before {
  content: "/";
  margin-right: 10px;
}

.breadcrumb li a {
  color: black;
  text-decoration: none;
}

.breadcrumb li a:hover {
  color: var(--ocean-blue);
  text-decoration: none;
}

.breadcrumb__item--active {
  color: #bfbfbf;
  font-weight: normal;
}
