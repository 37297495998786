.App {
  text-align: center;

  display: grid;
  height: 100vh;
  grid-template-rows: auto 1fr auto;
}

.align-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.icon {
  display: inline-block;
  width: 1em;
  height: 1em;
  font-size: inherit;
  vertical-align: -0.125em;
}

.input-group {
  position: relative;
}

.input-group-addon {
  position: absolute;
  top: 16px;
  right: 16px;
  z-index: 1;
  width: 24px;
  height: 24px;
  cursor: pointer;
}
