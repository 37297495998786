.App-login {
  min-height: 80vh;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;

  /* Bg */
  background-image: url("../images/officium-fullheader.png");
  height: 585px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.App-link {
  color: var(--ocean-blue);
}

input:hover::placeholder,
input:focus::placeholder {
  color: transparent;
}

.field {
  position: relative;
  margin-bottom: 18px;
  text-align: left;
}

/* TODO: global error management */

.field--errors input {
  border-color: var(--error);
}

.field--error {
  color: var(--error);
}
