footer {
  /* border: 1px solid black; */
  /* min-height: 200px; */
  display: flex;
  align-items: center;
}

footer .container {
  padding: 10px;
  max-width: 380px;
  margin: 0 auto;
  text-align: left;
}

footer p {
  font-size: 16px;
  line-height: 17px;
}

footer hr {
  margin: 20px 0;
  color: #bfbec3;
}

footer ul {
  padding-left: 20px;
}

footer li p {
  font-size: 14px;
}

.signin {
  text-align: center;
}

.confirm {
  text-align: left;
}
