.document {
  height: 74px;
  padding: 14.3px 15.9px 15.2px 16.5px;
  border-bottom: solid 1px #bfbec3;
  min-width: 240px;

  display: flex;
  align-items: flex-start;
  flex-direction: row;
  align-items: stretch;

  /* gap: 20px */
}

.document:last-child {
  border-bottom: none;
}

.document:hover {
  background-color: var(--white-two);
}

.document__icon {
  width: 44px;
  height: 44px;
  line-height: 46px;
  margin-right: 20px;
  border-radius: 5px;
  border: solid 1px var(--black);
  background-color: var(--white);
  text-align: center;
  vertical-align: middle;
}

.document__icon:hover {
  cursor: pointer;
}

.document__info a:hover {
  cursor: pointer;
}

.document__info + .document__icon {
  margin-right: 0;
}

.document__icon svg {
  font-size: 1.5em;
}

.document__info {
  width: 90%;
}

.document__visibility {
  width: 5%;
}

.document__date {
  opacity: 0.5;
  font-size: 14px;
  text-align: left;
  color: #444444;
}

@media screen and (max-width: 600px) {
  .document__date {
    white-space: nowrap;
    max-width: 200px;
    overflow: hidden;
    display: block;
    text-overflow: ellipsis; /* … */
  }
}
