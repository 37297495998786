.footerApp {
  display: flex;
  align-items: stretch;
}

.powerby {
  flex-grow: 1;
  padding: 10px 14px;
  text-align: right;
  line-height: 24px;
  font-size: 11px;
  color: var(--warm-grey);
}

.disclaimer {
  flex-grow: 1;
  padding: 10px 14px;
  text-align: left;
  line-height: 24px;
  font-size: 11px;
  color: var(--warm-grey);
}
