.infos {
  padding: 20px 40px;
  border-radius: 9px;
  box-shadow: 0 5px 5px 0 rgba(0, 0, 0, 0.05);
  background-color: var(--white);
  text-align: left;

  font-size: 12px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
  gap: 10px;
}

.infos div {
  flex-grow: 1;
}

.infos .label {
  color: var(--warm-grey);
}

.infos .value {
  font-weight: bold;
}

@media screen and (max-width: 800px) {
  .infos .label {
    display: block;
    width: 100%;
  }
}
