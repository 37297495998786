/*
Vos investissements = portfolios
Relevé de portefeuilles = reportings
Actifs non cotés = equity
Documentation = documents
*/


:root {
  --black: #222;
  --white: #fff;
  --ocean-blue: #0a5da2;
  --white-light: #f4f4f4;
  --light-periwinkle: #bed3f8;
  --light-peach: #ffe5c5;
  --light-teal: #bff1ef;
  --grey: #808080;
  --warm-grey: #8b8b8b;
  --black-3: #383838;
  --white-two: #f7f7f7;
  --error: #b51f10;
  --portfolios: #7F7F7F;
  --reportings: #002E45;
  --documents: #D9D9D9;
  --equity: #642E45;


  /* Not used */
  /* --azul: #1d73e4; */
  /* --pale-orange: #ffaa4f; */
  /* --tealish: #2fcbc9; */
  /* --raw-umber: #a24f0a; */
  /* --yellow-orange: #fdb800; */
  /* --dark: #1a181b; */
  /* --black-light: #353535; */
  /* --greyish: #b4b4b4; */
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  margin: 0;
  background-color: var(--white-light);
  color: var(--black);
  font-family: "SourceSansPro", "Roboto", "Oxygen", "Ubuntu", "Cantarell",
    "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@font-face {
  font-family: "SourceSansPro";
  src: local("SourceSansPro"),
    url("./fonts/SourceSansPro/SourceSansPro-Regular.ttf") format("truetype");
  font-weight: normal;
}

a {
  color: var(--ocean-blue);
}

h1 {
  justify-content: center;
  font-size: 16px;
  color: var(--black);
  font-weight: normal;
}

h2 {
  text-align: left;
  font-size: 16px;
  color: var(--black);
  font-weight: normal;
  margin-bottom: 18px;
}

input {
  border: solid 2px var(--black);
  border-radius: 5px;
  min-width: 300px;
  min-height: 52px;
  padding: 5px;
  font-weight: bold;
  font-size: 16px;
}

button {
  border: solid 2px var(--black);
  border-radius: 5px;
  min-width: 300px;
  min-height: 52px;
  background: var(--black);
  color: var(--white);
  font-weight: bold;
  font-size: 16px;
  cursor: pointer;
}

button:hover {
  border: solid 2px black;
  background: var(--white);
  color: black;
}
