/* Grid
--------- */

.portfolios .container {
  width: 90%;
  max-width: 1290px;
  margin: auto;
  display: grid;
  align-items: flex-start;
  grid-template-columns: repeat(12, 1fr);
  grid-template-rows: auto 1fr;
  gap: 20px;
}

.portfolios .filter {
  grid-column: 4 / span 9;
  grid-row: 1;
}

.distribution {
  min-height: 470px;
  grid-column: 1 / span 3;
  grid-row: 1 / span 3;
}

.datas {
  grid-column: 4 / span 9;
  grid-row: 3;
}

.infos {
  grid-column: 4 / span 9;
  grid-row: 2;
}

/* todo: responsive */

@media screen and (max-width: 800px) {
  .portfolios .filter {
    grid-column: 1 / span 12;
    grid-row: 1;
  }
  .distribution {
    min-height: 470px;
    grid-column: 1 / span 12;
    grid-row: 4;
    margin-bottom: 20px;
  }
  .datas {
    grid-column: 1 / span 12;
    grid-row: 3;
  }
  .infos {
    grid-column: 1 / span 12;
    grid-row: 2;
  }
}

/* Presentation
    --------- */

.distribution,
.datas {
  /* todo: to be factored with card */
  padding: 40px;
  border-radius: 9px;
  box-shadow: 0 5px 5px 0 rgba(0, 0, 0, 0.05);
  background-color: var(--white);
  text-align: left;
  min-height: 150px;
}

/* todo: responsive */
@media screen and (max-width: 800px) {
  .distribution,
  .datas,
  .infos {
    padding: 12px !important;
  }

  .distribution__chart {
    padding: 20px;
  }

  /* Level1 */
  .datas__icons {
    float: left;
    margin-left: -10px;
  }

  /* Level3 */
  .level3 .level3__infos {
    margin: 10px 0;
    display: flex;
    justify-content: flex-end;
    flex-direction: column;
    gap: 0 !important;
    width: 100%;
  }

  .level3 .level3__infos div {
    width: 100% !important;
    margin-bottom: 10px;
  }

  .level3 .label {
    width: 80%;
  }
  .level3 .amount {
    width: 20%;
    float: right;
  }
}

/* Datas
--------- */

table {
  width: 100%;
  border-spacing: 0;
  text-align: left;
}

th {
  text-align: left;
  background-color: #e3e3e3;
  white-space: nowrap;
}

.values th {
  border-bottom: 5px solid var(--white);
}

.datas .value {
  font-family: SourceSansPro;
  font-size: 10px;
  font-weight: bold;
  line-height: 20px;
  color: #b8b8b8;
  padding-left: 10px;
  border-top-left-radius: 9px;
}

.value-ptf {
  text-align: right;
  font-size: 10px;
  line-height: 20px;
  font-weight: bold;
  color: #b8b8b8;
  padding-right: 10px;
  border-top-right-radius: 9px;
}

tbody tr:hover {
  cursor: pointer;
  background-color: rgba(0, 0, 0, 0.04);
}

tbody td {
  border-bottom: 1px solid #ccc;
  height: 58px;
  padding: 0 10px;
}

.performance {
  color: var(--white);
  background-color: var(--black);
  height: 58px;
}

.performance th:first-child {
  text-align: left;
  padding-left: 10px;
}

.performance th {
  text-align: right;
  padding-right: 10px;
  background-color: var(--black);
}

tfoot td {
  height: 58px;
  padding: 0 10px;
  text-align: right;
}

tfoot td {
  height: 58px;
  padding: 0 10px;
  text-align: right;
}

.legend {
  font-size: 11px;
  color: var(--error);
}

@media screen and (max-width: 800px) {
  .performance {
    height: 38px;
  }
  .performance th:first-child {
    text-align: center;
  }
}

/* TODO: move to chart component */

.distribution__chart {
  margin-bottom: 20px;
}

.distribution__legend {
  list-style: none;
  list-style-type: none;
}

.distribution__legend li {
  font-size: 11px;
  font-weight: bold;
  text-align: left;
  color: #161417;
  margin-bottom: 5px;
}

.distribution__legend .icon {
  margin-right: 8px;
  width: 1.7em;
  height: 1.7em;
  vertical-align: -0.4em;
}

@media screen and (max-width: 800px) {
  .distribution__legend {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    gap: 10px;
  }

  .distribution__legend li {
    flex: 1 1 45%;
  }
}
