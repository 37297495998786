/* .page-reportings {} */

.page-fonds .container {
  width: 90%;
  max-width: 1290px;
  margin: auto;
  display: grid;
  align-items: flex-start;
  grid-template-columns: repeat(12, 1fr);
  grid-template-rows: auto 1fr;
  gap: 20px;
}

.page-fonds .filter-date {
  grid-column: 1 / span 3;
  grid-row: 1;
}

.page-fonds .filter-type {
  grid-column: 4 / span 5;
  grid-row: 1;
}

.page-fonds .filter-read {
  grid-column: 9 / span 2;
  grid-row: 1;
}

.documentsList {
  grid-column: 1 / span 12;
  grid-row: 2;
}

/* Responsive < 600px */
@media screen and (max-width: 600px) {
  .page-documents .filter-date {
    grid-column: 1 / span 7;
    grid-row: 1;
  }

  .page-fonds .filter-type {
    grid-column: 1 / span 12;
    grid-row: 2;
  }

  .page-fonds .filter-read {
    grid-column: 8 / span 5;
    grid-row: 1;
  }

  .page-fonds .documentsList {
    grid-column: 1 / span 12;
    grid-row: 4;
  }
}

/* Responsive 600/900 */
@media screen and (max-width: 900px) and (min-width: 600px) {
  .page-fonds .filter-date {
    grid-column: 1 / span 4;
    grid-row: 1;
  }

  .page-fonds .filter-type {
    grid-column: 5 / span 5;
    grid-row: 1;
  }

  .page-fonds .filter-read {
    grid-column: 10 / span 3;
    grid-row: 1;
  }

  .page-fonds .documentsList {
    grid-column: 1 / span 12;
    grid-row: 2;
  }
}
