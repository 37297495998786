.menu__btn {
  color: black;
  cursor: pointer;
  padding: 5px 15px;
  display: inline-block;
  position: absolute;
  left: 10px;
}

.menu__btn:hover {
  color: var(--black-3);
  background-color: rgba(0, 0, 0, 0.04);
  border-radius: 5px;
}

@media screen and (max-width: 600px) {
  .menu__btn__txt {
    display: none;
  }
}
