.overlay {
  background-color: rgba(255, 255, 255, 0.3);
  width: 100%;
  height: 100vh;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 1000;
}

/* .menu {
  width: 335px;
  height: 100vh;
  position: fixed;
  left: 0;
  top: 0;
  background-color: var(--white);
  display: flex;
  align-items: center;
  align-content: center;
} */

.menu {
  width: 235px;
  position: absolute;
  top: 7%;
  background-color: var(--white);
  display: flex;
  align-items: center;
  align-content: center;
  box-shadow: 5px 5px 10px var(--grey);
}

.menu ul {
  list-style: none;
  width: 100%;
}

.menu li {
  color: var(--black-3);
  display: block;
  line-height: 32px;
  list-style: none;
  text-align: left;
  width: 100%;
  font-size: 14px;
}

.menu li:hover {
  color: var(--black-3);
  background-color: rgba(0, 0, 0, 0.04);
}

.menu a {
  display: block;
  color: var(--black-3);
  text-decoration: none;
  height: 32px;
  padding-left: 0;
}

.menu a:hover {
  color: var(--ocean-blue);
  text-decoration: none;
  font-weight: bolder;
}

.menu ul .icon {
  margin: 0 16px;
  opacity: 0.5;
}

.btn--close {
  position: absolute;
  top: 36px;
  right: 20px;
  color: var(--black);
  padding: 5px 15px;
  cursor: pointer;
}

.btn--close:hover {
  color: var(--black-3);
  background-color: rgba(0, 0, 0, 0.04);
  border-radius: 5px;
}

/* Responsive < 600px */
@media screen and (max-width: 600px) {
  .overlay {
    background-color: rgba(0, 0, 0, 0.2);
    width: 100%;
    height: 100vh;
    position: fixed;
    left: 0;
    top: 0;
    z-index: 1000;
  }

  .menu {
    width: 100%;

    height: 100vh;
    position: fixed;
    left: 0;
    top: 0;
    background-color: var(--white);
    display: flex;
    align-items: center;
    align-content: center;
  }

  .menu ul {
    list-style: none;
    width: 100%;
  }

  .menu li {
    color: var(--black-3);
    display: block;
    line-height: 52px;
    list-style: none;
    text-align: left;
    width: 100%;
    font-size: 20px;
  }

  .menu a {
    display: block;
    color: var(--black-3);
    text-decoration: none;
    height: 52px;
    padding-left: 52px;
  }
}
