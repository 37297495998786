/* .datas__icons {
  border-radius: 50%;
  background-color: #005fb3;
  width: 12px;
  height: 12px;
  margin-right: 10px;
  display: inline-block;
  text-align: center;
} */

.level1 .icon {
  margin-right: 8px;
  width: 1.7em;
  height: 1.7em;
  vertical-align: -0.5em;
}

.label {
  text-align: left;
}

.amount {
  text-align: right;
  font-weight: bold;
  white-space: nowrap;
}

.level1 .label {
  font-size: 14px;
}

.level1 .amount {
  font-size: 16px;
}

.level2 .label {
  font-size: 12px;
}

.level3:hover {
  cursor: default;
  background-color: var(--white);
}

.level2 td {
  border-bottom: 1px dashed #ccc;
  height: 38px;
}

.level2 .amount {
  font-size: 13px;
}

.level3 .level3__infos {
  font-size: 11px;

  display: flex;
  justify-content: flex-end;
  gap: 10px;
  width: 100%;
}

.level3 .level3__infos div {
  width: 30%;
}

.level3 .label {
  display: inline-block;
  min-width: 140px;
}
