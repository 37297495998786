.App-loggedin {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  margin-bottom: 20px;

  /* Bg */
  background-image: url("../images/officium-fullheader.png");
  height: 100px;
  min-height: 100px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.buttons {
  display: inline-flex;
  align-items: center;
  position: absolute;
  right: 10px;
}
