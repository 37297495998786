
.logout {
  margin-left: 5px;
  padding: 5px 10px;
  cursor: pointer;
}

.logout .icon {
  width: 1.2em;
  height: 1.2em;
  font-size: 900;
  vertical-align: -0.25em;
}

.logout:hover {
  color: var(--black-3);
  background-color: rgba(0, 0, 0, 0.04);
  border-radius: 5px;
}

