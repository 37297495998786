.card {
  /* width: 370px;
  height: 145px; */
  padding: 40px;
  border-radius: 9px;
  box-shadow: 0 5px 5px 0 rgba(0, 0, 0, 0.05);
  background-color: var(--white);
  text-align: left;
}

.card h2 {
  text-align: left;
  font-size: 16px;
  color: var(--black);
  font-weight: bold;
}

.card__icon {
  width: 86px;
  height: 86px;
  margin: 0 19px 0 0;
  border-radius: 9px;
  background-color: var(--grey);
  display: flex;
  align-items: center;
  justify-content: center;
}

.card__icon .icon {
  width: 3em;
  height: 3em;
  font-size: inherit;
  color: black;
}

.card--empty p {
  opacity: 0.3;
}

.card a {
  display: block;
  text-decoration: none;
}

.card a:hover {
  opacity: 0.5;
}

.card .number {
  font-size: 42px;
  font-weight: bold;
  line-height: 1.24;
  color: var(--black);
}

/* Responsive < 600px */
@media screen and (max-width: 600px) {
  .card {
    padding: 12px;
  }
}
