.page-dashboard {
  /* border: 1px solid black; */

  width: 90%;
  max-width: 1290px;
  height: 50vh;
  display: grid;
  margin: 0 auto;

  grid-template-columns: repeat(12, 1fr);
  grid-template-rows: 1fr 1fr;
  gap: 20px;
}

/* Profil */
.card.card__profil {
  grid-column: 1 / span 4;
}

/* Communication */
.card__communication {
  grid-row: 2;
  grid-column: 1 / span 4;
}

/* Portefeuille */
.card__portfolios {
  grid-column: 5 / span 4;
}

/* Rapports */
.card__reportings {
  grid-column: 9 / span 4;
  grid-row: 1;
}

/* PrivateEquity */
.card__equity {
  grid-column: 5 / span 4;
  grid-row: 2;
}

/* Documents */
.card__documents {
  grid-column: 9 / span 4;
  grid-row: 2;
}

/* Cards
---- */
.card__portfolios,
.card__reportings,
.card__documents,
.card__equity {
  min-height: 145px;
  padding: 0;
}

.card__portfolios a,
.card__reportings a,
.card__documents a,
.card__equity a {
  height: 100%;
  display: flex;
  align-items: center;
  margin-left: 40px;
}

/* Portefeuille */
.card__portfolios .card__icon {
  background-color: var(--portfolios);
}

.card__portfolios .icon {
  color: var(--white-light);
}

/* Rapports */
.card__reportings .card__icon {
  background-color: var(--reportings);
}

.card__reportings .icon {
  color: var(--white-light);
}

/* Documents */
.card__documents .card__icon {
  background-color: var(--documents);
}

.card__documents .icon {
  color: var(--white);
}

/* Equity */
.card__equity .card__icon {
  background-color: var(--equity);
}

.card__equity .icon {
  color: var(--white-light);
}

/* Responsive < 600px */
@media screen and (max-width: 600px) {
  .page-dashboard {
    height: auto;
  }

  /* Profil */
  .card.card__profil {
    grid-column: 1 / span 12;
    grid-row: 6;
  }

  /* Communication */
  .card__communication {
    grid-column: 1 / span 12;
    grid-row: 1;
  }

  /* Portefeuille */
  .card__portfolios {
    grid-column: 1 / span 12;
    grid-row: 2;
  }

  /* Rapports */
  .card__reportings {
    grid-column: 1 / span 12;
    grid-row: 3;
  }

  /* PrivateEquity */
  .card__equity {
    grid-column: 1 / span 12;
    grid-row: 4;
  }

  /* Documents */
  .card__documents {
    grid-column: 1 / span 12;
    grid-row: 5;
  }
}

/* Responsive 600/900 */
@media screen and (max-width: 900px) and (min-width: 600px) {
  /* Profil */
  .card.card__profil {
    grid-column: 1 / span 6;
    grid-row: 1;
  }

  /* Communication */
  .card__communication {
    grid-column: 7 / span 6;
    grid-row: 1;
  }

  /* Portefeuille */
  .card__portfolios {
    grid-column: 1 / span 6;
    grid-row: 2;
  }

  /* Rapports */
  .card__reportings {
    grid-column: 7 / span 6;
    grid-row: 2;
  }

  /* PrivateEquity */
  .card__equity {
    grid-column: 1 / span 6;
    grid-row: 3;
  }

  /* Documents */
  .card__documents {
    grid-column: 7 / span 6;
    grid-row: 3;
  }
}
